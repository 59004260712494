import { SiteIdOverrideConfig } from "stores/wizard/config";

export const siteId509010011: SiteIdOverrideConfig = {
  car: {
    timeFormats: {
      timeFormat12Hour: true,
    },
    heading: {
      locTitleDesktopTextToken: "wizard.delta.cars.tab.title",
      locTitleToken: "wizard.mobile.delta.cars.tab.title",
      differentiatedTitles: true,
    },
    payWithCashCheckedToken: "wizard.delta.car.pwpToggle.title",
    payWithPointsCheckedToken: "wizard.delta.car.pwpToggle.title",
    iHaveDiscount: "wizard.car.having.brand.title",
    vendors: {
      AC: {
        includeInDropdown: false,
      },
      AD: {
        includeInDropdown: false,
      },
      EY: {
        includeInDropdown: false,
      },
      EP: {
        includeInDropdown: false,
      },
      FX: {
        includeInDropdown: false,
      },
      MW: {
        includeInDropdown: false,
      },
      NU: {
        includeInDropdown: false,
      },
      ZA: {
        includeInDropdown: false,
      },
      RO: {
        includeInDropdown: false,
      },
      SX: {
        includeInDropdown: false,
      },
      SV: {
        includeInDropdown: false,
      },
      SC: {
        includeInDropdown: false,
      },
      GI: {
        includeInDropdown: false,
      },
      NC: {
        includeInDropdown: false,
      },
      RW: {
        includeInDropdown: false,
      },
    },
  },
  hotel: {
    payWithPointsCheckedToken: "wizard.delta.hotel.pwpToggle.points",
    payWithCashCheckedToken: "wizard.delta.hotel.pwpToggle.cash",
    heading: {
      locTitleDesktopTextToken: "wizard.delta.hotels.tab.title",
      locTitleToken: "wizard.mobile.delta.hotels.tab.title",
      differentiatedTitles: true,
    },
  },
  vr: {
    navigation: {
      tabTitleToken: "wizard.vr.tab.title.label.custom.capitalization",
    },
    heading: {
      locTitleDesktopTextToken: "wizard.delta.rentals.tab.title",
      differentiatedTitles: true,
    },
    searchButtonAlongsideDates: false,
    payWithPointsCheckedToken: "wizard.delta.hotel.pwpToggle.points",
    payWithCashCheckedToken: "wizard.delta.hotel.pwpToggle.cash",
    vrPropertyTypes: [
      "VACATION_HOME",
      "CONDO",
      "APARTMENT",
      "CABIN",
      "CHALET",
      "CONDO_RESORT",
      "COTTAGE",
      "HOUSE_BOAT",
      "RESIDENCE",
      "VILLA",
      "CARAVAN_PARK",
    ],
  },
};
